<!--
 * @Author: 张博洋
 * @Date: 2021-11-22 11:17:53
 * @LastEditTime: 2021-12-06 11:06:27
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /yfz-h5/src/views/drug/components/storeOrder.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <div class="item bg-white radius8"
       @click="selectStore">
    <div class="hospital-name">
      <img :src="d.pharmacyPic"
           alt="">
      <span>{{d.pharmacyName}}</span>
    </div>
    <p class="mt12">{{d.address}}</p>
    <p class="mt4">门店电话：{{d.tel}}</p>
    <div class="drug-list">
      <div class="drug"
           v-for="(item,index) in d.drugs"
           :key="index">
        <img :src="item.images"
             alt="">
        <div class="info">
          <div class="info-top">
            {{item.commonName}} {{item.drugName}}
          </div>
          <div class="info-bottom">
            <span class="num">库存：{{item.num}}</span>
            <span class="price">
              ¥{{item.price}}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    d: {
      type: Object,
    },
  },
  methods: {
    selectStore() {
      localStorage.setItem('storeInfo', JSON.stringify(this.d))
      // this.$router.replace({
      //   name: 'drug.detail',
      // })
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss" scoped>
.item {
  padding: 12px 16px;
  margin-bottom: 8px;
  .hospital-name {
    display: flex;
    align-items: center;
    img {
      width: 20px;
      height: 20px;
      border-radius: 5px;
    }
    span {
      margin-left: 5px;
      font-size: 16px;
      font-weight: 500;
      color: #333333;
    }
  }
  p {
    font-size: 12px;
    font-weight: 400;
    color: #999999;
  }
  .drug-list {
    .drug {
      display: flex;
      justify-content: space-between;
      margin: 8px 0 12px 0;
      img {
        width: 80px;
        height: 80px;
      }
      .info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 12px;
        flex: 1;
        .info-top {
          overflow: hidden;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          overflow: hidden;
          text-overflow: ellipsis;
          vertical-align: middle;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          text-align: left;
        }
        .info-bottom {
          display: flex;
          justify-content: space-between;
          .num {
            font-size: 14px;
            font-weight: 400;
            color: #999999;
          }
          .price {
            font-size: 14px;
            font-weight: 500;
            color: #ff8200;
          }
        }
      }
    }
  }
}
</style>