<!--
 * @Author: 张博洋
 * @Date: 2021-11-20 17:59:05
 * @LastEditTime: 2021-11-30 10:52:02
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /hospital-h5/src/views/drug/storeList.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <div class="wrap flex">
    <div class="search">
      <van-search v-model="query.pharmacyName"
                  shape="round"
                  placeholder="请选择自提门店"
                  @input="getStoreList"
                  @search="getStoreList" />
    </div>
    <div class="list">
      <van-pull-refresh v-model="refreshing"
                        @refresh="onRefresh">
        <van-list v-model="loading"
                  :finished="finished"
                  :error.sync="error"
                  error-text="请求失败，点击重新加载"
                  @load="getData">
          <div class="pd8"
               v-if="list.length">
            <list-store :d='item'
                        v-for="(item,index) in list"
                        :key="index">
            </list-store>
          </div>
          <div v-if="!list.length && empty"
               class="empty full-h">
            <img width="120"
                 src="../../static/img/store-empty.png">
            <p>暂无门店</p>
          </div>
        </van-list>
      </van-pull-refresh>

    </div>
  </div>
</template>

<script>
import Search from '@/mixin/Search.js'
import listStore from './components/storeOrder.vue'
export default {
  mixins: [Search],
  components: {
    listStore
  },
  data () {
    return {
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      empty: false,
      error: false,
      query: {
        lat: 0,
        lng: 0,
        pageNo: 1,
        pageSize: 10,
        pharmacyName: '',
        prescriptionId: this.$route.query.prescriptionId
      }
    }
  },
  created () {

  },
  methods: {
    getStoreList () {
      console.log(this.$._)
      this.list = [];
      this.query.pageNo = 1
      this.getData()

    },
    getData () {
      this.loading = true;
      if (this.refreshing) {
        this.list = [];
        this.refreshing = false;
      }

      this.$axios({
        type: 'post',
        url: "drugOrder/pharmacy",
        data: this.query,
        elseData: {
          ajaxLoading: true,
          errorTip: false
        },
      }).then(res => {
        console.log(res)
        res.d.data || (res.d.data = []);
        this.list = [...this.list, ...res.d.data];

        this.loading = false;
        // 数据全部加载完成
        if (this.list.length >= res.d.total) {
          this.finished = true;
        }
        if (!res.d.data.length) {
          this.empty = true
          this.loading = true;
        }
      }).catch(e => {
        this.error = true
        this.loading = false;
      });

    },
    onRefresh () {
      this.query.pageNo = 1
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.$axios({
        type: 'post',
        url: "drugOrder/pharmacy",
        data: this.query,
        elseData: {
          ajaxLoading: true,
          errorTip: false
        },
      }).then(res => {
        console.log(res)
        res.d.data || (res.d.data = []);
        this.list = [...res.d.data];
        this.loading = false;
        this.refreshing = false
        // 数据全部加载完成
        if (this.list.length >= res.d.total) {
          this.finished = true;
        }
        if (!res.d.data.length) {
          this.empty = true
        }
      }).catch(e => {
        this.error = true
        this.loading = false;
      });
    },

  }
}
</script>

<style lang="scss" scoped>
.van-search {
  /deep/.van-cell__value {
    font-size: 14px;
  }
}
.list {
  flex: 1;
  overflow-y: auto;
}
.empty {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  img {
    margin-top: calc(50% - 116px);
  }
  color: #999;
}
</style>